import './App.css';
import React from 'react';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import kindToken from './contracts/KindToken.json';
import kindTokenSale from './contracts/KindTokenSale.json';
import tokenTimelock from './contracts/TokenTimelock.json';
import HeaderComponent from './components/HeaderComponent';
import ConnectorComponent from './components/ConnectorComponent';
import ClaimComponent from './components/ClaimComponent'

function App() {
  return (
    <div className="App">
      {HeaderComponent()}
      {ConnectorComponent()}
    </div>
  );
}

export default App;
