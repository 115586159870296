import { ethers } from 'ethers';
import kindToken from './contracts/KindToken.json';
import kindTokenSale from './contracts/KindTokenSale.json';
import tokenTimelock from './contracts/TokenTimelock.json';
const coinAbi = kindToken.abi;

export default function getCoinContract(ethereum, coin) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    
    const coinContract = new ethers.Contract(coin, coinAbi, signer);

    return coinContract;
}