import React from "react";

function HeaderComponent() {
    return (
      <div>
          <img src="logo192.png"></img>
      </div>
    );
}

export default HeaderComponent;