import React from "react";
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import getSaleContract from './../getSaleContract';
import getCoinContract from './../getCoinContract';

function ClaimComponent(props) {
    const [balance, setBalance] = useState(0);
    const [investment, setInvestment] = useState(0);
    const [claimable, setClaimable] = useState(0);

    const claimHandler = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            alert('Install Metamask');
        }

        try {
            let saleCtx = getSaleContract(ethereum, props.sale);
            await saleCtx.claimTokens({ gasLimit: 120000});
            let c = await saleCtx.claimableTokens()
            setClaimable(ethers.utils.formatEther(c));

            console.log('claimed');
        } catch(err) {
            console.log('Wrong chain', err, props.sale);
        }
    }

    useEffect(async () => { 
        try {
            const { ethereum } = window;

            if(props.account) {
                let coinCtx = getCoinContract(ethereum, props.coin);
                let saleCtx = getSaleContract(ethereum, props.sale);
                let b = await coinCtx.balanceOf(props.account);
                let i = await saleCtx.getUserContribution(props.account);
                let c = await saleCtx.claimableTokens();
                setBalance(ethers.utils.formatEther(b));
                setInvestment(ethers.utils.formatEther(i));
                setClaimable(ethers.utils.formatEther(c));
            }
        } catch(err) {
            console.log(err);
        }
    }, [])

    useEffect(async () => {
        try {
            const { ethereum } = window;

            if(props.account) {
                let coinCtx = getCoinContract(ethereum, props.coin);
                let saleCtx = getSaleContract(ethereum, props.sale);
                let b = await coinCtx.balanceOf(props.account);
                let i = await saleCtx.getUserContribution(props.account);
                let c = await saleCtx.claimableTokens();
                setBalance(ethers.utils.formatEther(b));
                setInvestment(ethers.utils.formatEther(i));
                setClaimable(ethers.utils.formatEther(c));
            }
        } catch(err) {
            console.log(err);
        }
    }, [claimable])

    return (
      <div>
          <ul className="list-group mb-2">
              <li className="list-group-item">Investment: <strong>{investment}</strong> {'$'+props.nativeToken}</li>
              <li className="list-group-item">Claimable: <strong>{claimable}</strong> $KIND</li>
              <li className="list-group-item">Balance: <strong>{balance}</strong> $KIND</li>
          </ul>
          
          {claimable > 0 ? <button className="btn btn-primary" onClick={claimHandler}>Claim</button> : null}
      </div>
    );
}

export default ClaimComponent;