import React from "react";
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import getSaleContract from './../getSaleContract';
import ClaimComponent from './ClaimComponent';

function ConnectorComponent() {
    const [nativeToken, setNativeToken] = useState(null);
    const [coin, setCoin] = useState(null);
    const [sale, setSale] = useState(null);
    const [account, setAccount] = useState(null);
    const [whitelisted, setWhitelisted] = useState(null);

    const intializeAccount = async () => {
        try {
            const { ethereum } = window;
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            setAccount(accounts[0]);

            if(account) {
                let saleCtx = getSaleContract(ethereum, sale);
                setWhitelisted(await saleCtx.whitelist(account));
            }
        } catch(err) {
            console.log(err);
        }
    }

    const checkWalletIsConnected = async () => {
        const { ethereum } = window;
        if (!ethereum) {
            console.log('check metamask');
        } else {
            console.log('wallet exists');
        }
    }

    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            alert('Install Metamask');
        }

        try {
            const provider = new ethers.providers.Web3Provider(ethereum);
            
            let network = await provider.getNetwork();
            console.log('network', network.chainId);

            switch(nativeToken) {
                case 'ETH': {
                    if(network.chainId != 3) {
                        alert(`You are on ${network.name}, should be on ETH Ropsten`);
                        return;
                    }
                    break;
                }
                case 'BSC': {
                    if(network.chainId != 97) {
                        alert(`You are on ${network.name}, should be on Pre BSC`);
                        return;
                    }
                    break;
                }
                case 'MATIC': {
                    if(network.chainId != 80001) {
                        return;       
                    }
                    break;
                }
            }

            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            setAccount(accounts[0]);
    
            console.log('accounts[0]', accounts[0]);
        } catch(err) {
            console.log('Wrong chain', err);
        }
    }

    const disconnectWalletHandler = async () => {
        setAccount(null);
    }

    const connectWalletButton = (nativeToken) => {
        return (
            <button onClick={connectWalletHandler} className='btn btn-secondary'>
            Connect Wallet ({nativeToken})
            </button>
        )
    }

    const disconnectWalletButton = () => {
        return (
            <button onClick={disconnectWalletHandler} className='btn btn-primary'>
                {account.substr(0,7)}... (disconnect)
            </button>
        )
    }

    const nativeTokenSelector = () => {
        return (
            <ul className="list-group">
                <li role="button" onClick={selectEth} className={`list-group-item ${nativeToken == 'ETH' ? "active" : ""}`}>Ethereum Ropsten</li>
                <li role="button" onClick={selectBsc} className={`list-group-item ${nativeToken == 'BSC' ? "active" : ""}`}>Pre BSC</li>
                <li role="button" onClick={selectMatic} className={`list-group-item ${nativeToken == 'MATIC' ? "active" : ""}`}>Polygon/Matic Mumbai</li>
            </ul>
        )
    }

    const selectEth = async () => {
        setNativeToken("ETH");
        setCoin("0xC95D567b005737914F04456C1155BF29466CAF39");
        setSale("0x407eDd49BBe36E717b1e2737b24e68FAdF67600a");
        setAccount(null);
    }

    const selectBsc = async () => {
        setNativeToken("BSC");
        setCoin("0x363bb76B24b66F032f89E7583be40648ac1c0f20");
        setSale("0xdBb4b161a5E23CAa2430543A12f398a41a9c6902");
        setAccount(null);
    }

    const selectMatic = async () => {
        setNativeToken("MATIC");
        setCoin("0xd50cD0253Be9488E374B6b1e85951f035b2187F2");
        setSale("0x291678D398346F6A04e1D3E739728c2E6B1870f3");
        setAccount(null);
    }

    useEffect(() => {
        checkWalletIsConnected();        
    }, []);

    useEffect(async () => { 
        try {
            const { ethereum } = window;

            if(account) {
                let saleCtx = getSaleContract(ethereum, sale);
                setWhitelisted(await saleCtx.whitelist(account));
                console.log('set whitelisted');
            }
        } catch(err) {
            console.log(err);
        }
    }, [account])

    return (
      <div>
          <h1>Select network</h1>
          <div className="mb-4 offset-4 col-4">
            {nativeTokenSelector()}    
          </div>

          {!nativeToken ? null : 
            <div className="alert mb-4 offset-3 col-6">
                <h2>$KIND address</h2>
                <code>{coin}</code>
            </div>}

          <h2>Connect wallet</h2>
          <div className="mb-4">
            {!nativeToken ? 'Select network first' : null}
            {nativeToken && !account ? connectWalletButton(nativeToken) : null}
            {nativeToken && account ? disconnectWalletButton() : null}
          </div>
          
          <h2>Claims</h2>
          <div className="mb-4 offset-3 col-6">
            {account && !whitelisted ? 'Not whitelisted' : null}
            {account && whitelisted ? <ClaimComponent account={account} nativeToken={nativeToken} coin={coin} sale={sale} /> : null}
            {!account ? 'Connect wallet first' : null}
          </div>
          
          
      </div>
    );
}

export default ConnectorComponent;