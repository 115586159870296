import { ethers } from 'ethers';
import kindToken from './contracts/KindToken.json';
import kindTokenSale from './contracts/KindTokenSale.json';
import tokenTimelock from './contracts/TokenTimelock.json';
const coinAbi = kindToken.abi;
const saleAbi = kindTokenSale.abi;
const timelockAbi = tokenTimelock.abi

export default function getSaleContract(ethereum, sale) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

    console.log('creating contract', sale, saleAbi, signer);
    
    const crowdsaleContract = new ethers.Contract(sale, saleAbi, signer);

    return crowdsaleContract;
}